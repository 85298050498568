<template>
	<div></div>
</template>
<script>
	import {
		useStore
	} from "vuex";
	import {
		useRoute,
		useRouter,
		onBeforeRouteLeave
	} from "vue-router";
	export default {
		name: "Redirect",
		setup() {
			const route = useRoute();
			const router = useRouter();
			const route_query = route.query;
			const route_params = route.params;
			const store = useStore(); // 使用useStore方法
			// 使用如下：在使用页面调用
			/* onBeforeRouteLeave((to) => {
				// 固定判断 to.path !== "/redirect" && route_query.redirect && to.path !== ("/" + route_query.redirect)
				// 看情况判断 to.path !== "/addRole"
				if (to.path !== "/addRole" && to.path !== "/redirect" && route_query.redirect && to.path !== ("/" + route_query.redirect)) {
					router.replace({
						name: 'Redirect',
						params: {
							path: route_query.redirect,
							isReset: true
						}
					});
					return false;
				}
			}); */
			//离开当前的组件，触发
			onBeforeRouteLeave((to) => {
				console.log(to);
				console.log(route_params);
				console.log("keepAlive为true并且确定重置")
				// isReset 是否重置 默认为false
				let isReset = "isReset" in route_params;
				// keepAlive为true
				if (to.meta.keepAlive) {
					// route_params 存在 isReset 字段
					if (isReset && route_params.isReset) {
						to.meta.resetType = "1";
					// 如果不存在则默认重置
					} else if(!isReset) {
						to.meta.resetType = "1";
					} else {
						// isReset存在 并且 route_params.isReset 为 false
						to.meta.resetType = "0";
					}
				}
			});
			const fResult = () => {
				console.log("执行重定向")
				// 如果来源于平台，并且ID存在
				// https://h5-hongying-pre.utroninfo.com/#/redirect?form=pingtai&planId=BET1642059805698790
				if (route_query.form && route_query.form === "pingtai" && route_query.planId) {
					store.dispatch("other/UOPTId", {
						id: route_query.planId,
						isUse: false
					});
					router.replace({
						path: "/home"
					});
				} else if (route_params.path) {
					console.log(route_params.path)
					// 跳转到方案详情
					router.replace({
						path: "/" + route_params.path,
						query: route_query
					});
				} else {
					router.replace({
						path: "/",
					});
				}
			};
			fResult();
		}
	}
</script>
