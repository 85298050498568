<template>
	<div class="generalTxt app_flex">
		<div class="generalTxt_title app_title_b">
			<van-sticky>
				<van-nav-bar :title="oCPData.title" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="app_scroll">
			<iframe :src="oCPData.iframeSrc" scrolling="auto" frameborder="0" id="generalTxt_iframe"></iframe>
		</div>
	</div>
</template>

<script>
	import {
		reactive,
		nextTick
	} from "vue"
	import {
		useRoute,
		onBeforeRouteLeave
	} from "vue-router"
	import {
		Toast
	} from 'vant'
	import {
		fnIsHasHistory
	} from "@/utils/router.js"
	import config from "@/utils/config.js"
	export default {
		name: "GeneralTxt",
		setup() {
			const url = config[process.env.VUE_APP_TITLE].agreementUrl;
			const route = useRoute();
			// o: 对象; CP：currentPage当前页;data: 数据
			const oCPData = reactive({
				title: "协议",
				iframeSrc: ""
			})
			const oUrl = {
				information: {
					'title': '信息同步协议',
					'url': 'https://www.xingyuninfo.com/flow/agreement/information.html'
				},
				issueBill: {
					'title': '发单协议',
					'url': url + 'IssueBill.html'
				},
				playWayZQ: {
					'title': '玩法规则',
					'url':'https://www.xingyuninfo.com/flow/agreement/playWayZQ.html '
				},
				playWayLQ: {
					'title': '玩法规则',
					'url': url + 'playWayLQ.html'
				},
				playWayBD: {
					'title': '玩法规则',
					'url': url + 'playWayBD.html'
				},
				privacy: {
					'title': '隐私协议',
					'url': 'https://www.xingyuninfo.com/flow/agreement/privacy.html'
				},
				user: {
					'title': '用户协议',
					'url':  'https://www.xingyuninfo.com/flow/agreement/user.html'
				},
				synchronization: {
					'title': '用户信息同步协议',
					'url': 'https://www.xingyuninfo.com/flow/agreement/synchronization.html'
				},
				helpCenter: {
					'title': '帮助中心',
					'url': 'https://www.xingyuninfo.com/flow/agreement/helpCenter.html'
				},
				documentary: {
					'title': '跟单协议',
					'url': url + 'documentary.html'
				},
				comment: {
					'title': '评论协议',
					'url':  'https://www.xingyuninfo.com/flow/agreement/comment.html'
				},
				optimize: {
					'title': '优化规则',
					'url': url + 'optimize.html'
				},
			};

			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/");
			};
			// 获取协议
			const getAgreement = () => {
				console.log(route.query);
				const {
					agreement
				} = route.query
				oCPData.iframeSrc = oUrl[agreement].url;
				oCPData.title = oUrl[agreement].title;

				nextTick(() => {
					const toast1 = Toast({
						type: "loading"
					});
					const iframe = document.getElementById('generalTxt_iframe');
					if (!iframe) {
						toast1.clear();
					} else if (iframe.attachEvent) {
						iframe.attachEvent('onload', function() {
							// console.log('iframe已加载完毕')
							toast1.clear();
						})
					} else {
						iframe.onload = function() {
							// console.log('iframe已加载完毕')
							toast1.clear();
						}
					}
				})
			}
			getAgreement();

			//离开当前的组件，触发
			onBeforeRouteLeave((to) => {
				if (to.meta.keepAlive) {
					to.meta.resetType = "2";
				}
			})
			return {
				oCPData,
				fLeft
			}
		}
	}
</script>

<style scoped lang="scss">
	.generalTxt {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		background-color: #FFFFFF;
	}

	iframe {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
</style>
