<template>
	<div class="result">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar class="listGDR_bar" title="充值成功" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		
		<div class="result_body">
			<img src="~@/assets/images/img_025.png" alt="">
		</div>
	</div>
</template>

<script>
	import {
		fnIsHasHistory
	} from "@/utils/router.js"
	export default {
		name: "result",
		setup() {
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/loginPw");
			};
			return {
				fLeft
			}
		}
	}
</script>

<style scoped lang="scss">
	.result_body {
		text-align: center;
		&>img {
			margin: 5.3866rem auto 0;
			width: 6.64rem;
			height: auto;
		}
	}
</style>
