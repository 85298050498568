<template>
	<div id="web" class="web app_flex">
		<div class="app_title_b" v-if="oYCData.bShowTitle">
			<van-sticky>
				<van-nav-bar class="listGDR_bar" :title="oYCData.title" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="web_content">
			<iframe :src="oYCData.iframeSrc" scrolling="auto" frameborder="0" id="web_iframe"></iframe>
		</div>
	</div>
</template>

<script>
	import {
		reactive,
		nextTick
	} from 'vue';
	import {
		useRoute,
		useRouter,
		onBeforeRouteLeave
	} from 'vue-router';
	import {
		useStore
	} from "vuex";
	import config from "@/utils/config.js"
	export default {
		name: "Web",
		setup() {
			const route = useRoute();
			const router = useRouter();
			const store = useStore();
			const route_query = route.query;
			const url = config[process.env.VUE_APP_TITLE].webUrl;
			//离开当前的组件，触发
			onBeforeRouteLeave(() => {
				window.removeEventListener("message", fParent)
			});
			const oNCData = reactive({
				lotteryShop: {
					title: '彩店信息',
					url: null,
					bShowTitle: true
				},
				editor: {
					title: '详情信息',
					url: url + 'editor.html',
					bShowTitle: true
				},
				recharge:{
					title:'充值',
					url: null,
					bShowTitle: false
				}
			});
			const oYCData = reactive({
				title: "详情信息",
				iframeSrc: null,
				bShowTitle: true
			});
			// 获取页面
			const fGetType = () => {
				const {
					title,
					url,
					bShowTitle
				} = oNCData[route_query.type];
				oYCData.title = title;
				oYCData.iframeSrc = url ? url : route_query.url;
				oYCData.bShowTitle = bShowTitle;
				console.log(oYCData)
				if (route_query.type === "editor") {
					const sEditor = store.state.other.sEditor;
					console.log(sEditor)
					nextTick(() => {
						const iframe = document.getElementById('web_iframe');
						if (!iframe) {
							return
						} else if (iframe.attachEvent) {
							iframe.attachEvent('onload', () => {
								console.log('iframe已加载完毕')
								document.getElementById('web_iframe').contentWindow.postMessage(sEditor,'*');
							})
						} else {
							iframe.onload = function() {
								console.log('iframe已加载完毕')
								document.getElementById('web_iframe').contentWindow.postMessage(sEditor,'*');
							}
						}
					})
				}
			};
			const fLeft = () => {
				router.go(-1)
			};
			
			fGetType();
			
			const fParent = () => {
				console.log(route_query.redirect)
				if (route_query.redirect && route_query.redirect !== "goback") {
					router.push({
						path: "/" + route_query.redirect
					});
				} else {
					router.go(-1)
				}
			};
			window.addEventListener("message", fParent)
			return {
				oYCData,
				fLeft
			}
		}
	}
</script>

<style scoped lang="scss">
	.web {
		width: 100%;
		height: 100%;

		.web_content {
			width: 100%;
			height: 100%;
			box-sizing: border-box;
		}

		iframe {
			width: 100%;
			height: 100%;
		}
	}
</style>
